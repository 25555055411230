// Variables
// Using a mix of SASS for easier readability and CSS for easier changability

// Imports for carousel
@import '../node_modules/slick-carousel/slick/slick.css';
@import '../node_modules/slick-carousel/slick/slick-theme.css';

// Clip width (here so it can cascade to media queries)
$front-width: 30px;
$back-width: 10px;

// Colors
// Neutral Colors
$black: #000000;
$white: #FFFFFF;
$off-white: #F6F8FA;
$grey: #D0D9E2;

// Carousel Backclip
$muzli: #CC7D3B;

// BH9 Colors
$darker-blue: #081646; // BG Color and Text Body 
$dark-blue: #193787;
$blue: #1447C8;
$candid-blue: #6AC5E3;
$orange: #FF9C4A; // Nav bar line

// Main gradient
.bh-gradient {
    // Fallback in case gradient fails to show
    background-color: $dark-blue;

    // Gradient created
    background-image: linear-gradient(
        130deg, 
        $dark-blue 15%,
        $candid-blue 30%,
        $blue 60%,
        $orange 75%,
        );
}

// Text gradient
.gradient-text {

    // background-size and properties to repeat
    background-size: 100%;
    background-repeat: repeat;

    // Gradient shows through text by rendering
    // mask as background with transparent text showing gradient
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
	-webkit-background-clip: text;
    -moz-background-clip: text;
}

// Standard fill case for white text
.standard-text {
    -webkit-text-fill-color: $blue;
    -moz-text-fill-color: $blue;
}

// Text color dark-blue for text
.desatblue-text {
    color: $dark-blue;
}

// General Styles -- from bh9.scss
// General Styles -- from bh9.scss
// General Styles -- from bh9.scss
* {
    margin: 0 auto; // centers all elements
    margin: 0 auto; // centers all elements
    margin: 0 auto; // centers all elements
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    line-height: 125%;
    line-height: 125%;

    // Transition all elements on the screen
    // (except FAQ panel, which has conflicting transitions)
    // (and except certian nav stuff, which we don't want)
    &:not(.panel):not(.show-nav):not(.left):not(.right) {
        transition-duration: 1s;
        transition-property: margin, padding, width, font-size;
    }
}

body {
    //margin: 0 auto; // centers all elements
    //margin: 0 auto; // centers all elements
    margin-top: 2rem; // spacing
    background-color: $off-white;
    color: $darker-blue; // default text color
    text-align: left;
    text-align: left;
}

h1 {
    font-size: 5.64rem;
    font-weight: 500; // semi-bold
}

h2 {
    font-size: 2.5rem;
    font-weight: 600;
    padding-bottom: 1rem;
}

h3 {
    font-size: 23px;
    font-weight: 600;
    line-height: 1.7rem;
    padding-top: 2.7rem;
    font-size: 5.64rem;
    font-weight: 500; // semi-bold
}

h2 {
    font-size: 2.5rem;
    font-weight: 600;
    padding-bottom: 1rem;
}

h3 {
    font-size: 23px;
    font-weight: 600;
    line-height: 1.7rem;
    padding-top: 2.7rem;
}

p {
    font-size: var(--body-font-size);
    padding-bottom: 1rem;
    line-height: 133%; // spacing between lines

    &:last-child {
        padding-bottom: 0;
    }
}

a {
    text-decoration: none;
    // color: $blue;
    // border-bottom: 2px solid $blue;

    &:hover, &:focus, &:active {
        font-weight: bold;
        text-decoration: none;

        // -webkit-text-fill-color: $off-white;
        // -moz-text-fill-color: $off-white;
    }
    line-height: 133%; // spacing between lines

    &:last-child {
        padding-bottom: 0;
    }
}

a {
    text-decoration: none;
    // color: $blue;
    // border-bottom: 2px solid $blue;

    &:hover, &:focus, &:active {
        font-weight: bold;
        text-decoration: none;

        // -webkit-text-fill-color: $off-white;
        // -moz-text-fill-color: $off-white;
    }
}

// Typography
:root {
    --body-font-size: 1.4rem;
}

// Mixins - imported from bh9.scss
@mixin button-primary { // main orange button
    background-color: $orange; 
    text-decoration: none;
    user-select: none;
    padding: 1rem 1.5rem;
    border-radius: 6px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    color: $dark-blue;
    font-size: var(--body-font-size);
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
        border: 2px solid $darker-blue;
        background-color: $darker-blue;
        color: $white;
    }

    &:active {
        transform: translateY(4px);
    }

    @media screen and (max-width: 900px) {
        font-size: 1em;
    }
}

@mixin button-secondary { // "clear" darker-blue button
    @include button-primary;
    background-color: $off-white;
    color: $darker-blue;
    border: 2px solid $darker-blue;

    &:hover {
        background-color: $darker-blue;
        color: $white;

    }
}

@mixin button-effect($color) {
    transition: 0.2s all;
    &:hover {
        background-color: lighten($color, 5%);
    }
    &:active {
        transform: translateY(4px);
    }
}

// Main Site Layout

// Navbar -- adopted from bh-9
nav {
    background-color: $white;
    border-bottom: 2px solid rgba($candid-blue, 25%);
    display: flex;
    justify-content: space-between;
    font-size: var(--body-font-size);
    height: 100px;
    position: fixed;
    padding: 0px 130px 0px 8rem;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;

    .top, .right {
        display: flex;
        align-items: center;
    }

    .left {
        margin: 1rem 2rem 1rem 1rem; // readjust to center
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    #toggle {
        margin-right: 2rem;
        color: $dark-blue;
        font-size: 2rem;
        width: 2rem;
        display: none;
    }

    a {
        margin-right: 1rem;
        text-decoration: none;
        color: $blue;
        margin-left: 0;
    }


    .link {

        &.active {
            font-weight: bold;
            color: $orange;
            //border-color: $orange;

            &:hover {
                border-color: $orange;
            }
        }
    }

    #register {
        @include button-primary;
    }

    #logo {
        width: 80px;
        border: none;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

// MLH Banner
#mlh-trust-badge {
    display: block;
    position: fixed;
    top: 0;
    right: 5px;
    height: 0;
    z-index: 6;
}

// Mobile Greyout
.mobile-grayout {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.25;
    z-index: 3;
    cursor: pointer;
    display: none;
}

// Section padding
section {
    padding-top: 190px; // Set up to avoid nav overlap when linked
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}


// Hero
#hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
    width: auto;
    padding: 150px 120px 120px;

    // positioning
    background-image: url("../assets/bh10/main-site/2-hero/colorfullines-hero.svg");
    background-repeat: no-repeat;
    background-position: 850px 170px;

    #hero-content {
        margin: 0;

        h3:not(h3:last-of-type) {
            padding-bottom: 0;
        }

        h3:last-of-type {
            padding-bottom: 1.5rem;
        }

        // Fading in and out text
        .text-show {
            color: $darker-blue;
            opacity: 1;
            transition: all 1s ease;
        }

        .text-fadeout {
            color: $darker-blue;
            opacity: 0;
            transition: all 1s ease;
        }

        .shed-link {
            text-decoration: none;
            width: max-content;
            border-bottom: 2px solid $darker-blue;
        }

        #hero-buttons {
            display: flex;
            max-width: fit-content;
            justify-content: flex-start;
            align-items: center;
            margin: 0;

            #login {
                @include button-secondary;
                margin-right: 2rem;
            }

            #discord {
                @include button-secondary;
            }
        }
        
        #register {
            @include button-primary();
            width: 64%;
            // margin-top: 1rem;
        }
    }

    #graphicwrap {
        border: 2px solid #ffffff;
        width: max-content;
        height: auto;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("../assets/bh10/main-site/2-hero/colorfullines-hero.svg");
    }

     #linegraphic {
        position: relative;
    }

    #rickygraphic {
        max-width: 100%;
        margin: 0 auto;
    }

    #linegraphic {
        position: relative;
        z-index: -1;
    }
}

// Photo Carousel

.carousel {
    $slide-size: 280.19px; // smaller slides
    padding: 120px 0px; // Set up to avoid nav overlap when linked
    //padding-top: 240px;
    flex-flow: column nowrap;
    justify-content: center;
    max-width: 100%;
    margin: 0 auto;

    .slick-list {
        // Slider depends on having the overflow-x hidden,
        // CSS (per spec) doesn't allow separate overflow-y and x.
        // So, we add some padding to cheat it.
        // (https://css-tricks.com/popping-hidden-overflow/)
        padding-top: 60px !important; // Overrides slick.css
        margin-top: -60px;
        overflow: hidden;
    }

    .slick-slide {
        height: $slide-size;
        margin-right: 20px; // Same margin as wire top calculation

        &:focus {
            outline: none;
        }

        .wire {
            position: absolute;
            width: 200%;
            margin: 0;
            height: 15px;
            background: white;
            top: -(15px + 20px); // Emphasize 20px margin on 15px height
            z-index: 2;
        }
    }
  
        // Offset clips above the carousel image
        // Pos: abs because pos: rel causes the two clips,
        // despite having different offsets,
        // to interfere with each other when trying to adjust their offset.
        .front-clip, .back-clip {
            position: absolute;
            top: -60px;
        }

        .front-clip {
            // Front clip: center left edge, then center the clip itself.
            margin-left: calc($slide-size / 2) - calc($front-width / 2);
            width: $front-width;
            height: 80px;
            background-color: $orange;
            z-index: 3;
        }

        .back-clip {
            width: $back-width;
            height: 60px;
            background-color: $muzli;
            // Back clip: center left edge, then center the clip itself,
            // then do left offset from top clip
            margin-left: calc($slide-size / 2) - calc($front-width / 2) - $back-width;
            margin-top: 10px; // Vertical offset from top clip
            z-index: 0;
        }

        img {
            position: relative;
            top: 0;
            width: $slide-size;
            height: $slide-size;
            border-radius: 20px;
            cursor: pointer;
            transition: 0.3s;
            z-index: 2;

            &:hover {
                filter: brightness(125%);
            }
        }

    .slick-arrow {
        width: 60px;
        height: 60px;
        background-color: $darker-blue;
        border: 2px solid #A3A9CA;
        box-sizing: border-box;
        border-radius: 50%;
        // slick-theme has a transform we don't need!
        transform: none;
        @include button-effect($orange); // changed to orange color
    }

    // Make sure these appear over the slides!
    .slick-prev, .slick-next {
        z-index: 3;
    }

    // Use FontAwesome as pseudo-element so we don't
    // have to fork slick-carousel.
    // (https://fontawesome.com/how-to-use/on-the-web/advanced/css-pseudo-elements)
    .slick-prev::before, .slick-next::before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }

    .slick-prev {
        left: 1%;

        &::before {
            content: "\f060";
        }
    }

    .slick-next {
        right: 1%;

        &::before {
            content: "\f061";
        }
    }

}

#modal {
    display: none;
    position: fixed;
    cursor: pointer;
    z-index: 100;
    padding: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(black, 0.9);
}

#modal-content {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 80%;
    max-width: 700px;
    cursor: default;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@keyframes zoom {
    from { transform: scale(0); }
    to   { transform: scale(1); }
}

#close {
    position: absolute;
    top: 15px;
    right: 35px;
    color:white;
    font-size: 1.5em;
    font-weight: bold;
    transition: 0.3s;
}

#close:hover,
#close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

// About

#arrowgraphic { // arrow graphic
    position: relative;
}

#about {
    margin-top: 60px;
    // margin-left: unset;
    // margin-right: unset;
    padding-top: 0px; // overrides section inheritance
    display: flex;
    align-items: left;
    justify-content: left;

    // #about-text {
    //     display: inline-block;
    //     text-align: left;
    // }

    #about-text {
        margin: 0 120px;

        h2 {
            font-size: 42px;
        }

        p {
            font-size: 21px;
        }
    }

    #about-cards {
        display: flex;
        //justify-content: left;
        margin: 2rem 120px 5rem 120px;

        .about-card-space {
            position: relative;
            width: 50%;

            .about-card {
                position: relative;
                flex-direction: column;
                background-color: white;
                border: 5px solid $candid-blue;
                border-radius: 6px;
                width: 95%;
                margin: 0;

                .about-header {
                    position: relative;
                    flex-direction: row;
                    border-bottom: 5px solid $candid-blue;
                    max-width: 100%;
                    height: 20%;
                    padding: 2rem 0rem;

                    h3 {
                        padding: 0;
                    }

                    #blue4pt {
                        position: absolute;
                        top: -60px;
                        right: -60px;
                    }

                    h3 {
                        font-size: 28px;
                        //margin-bottom: 12rem;
                        margin-left: 30px;
                        font-weight: 600;
                        color: $dark-blue;
                        width: fit-content;
                        z-index: 4;

                        line-height: 18px; // allows border-bottom to shift up
                        border-bottom: 14px solid $candid-blue;
                    }
                }
            }

            .about-end { // accomodates the orange star on bottom
                position: relative;
                flex-direction: row;
                margin-top: -4rem; // allows overlap on about card
                margin-left: -6rem;
                max-width: 100%;
                height: 10%;

                #orange6pt { // enlargened and fixed
                    position: absolute;
                    width: 160px;
                    height: auto;
                }
            }
        }
            
        p {
            padding: 20px;
            color: $dark-blue;
        }
    }

    // By The Numbers

    #about-stats {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 120px;

        #stats-content {

            width: 75%;

            #stats {
                max-width: 85%;
                display: grid; 
                gap: .5rem;
                grid-template-columns: 40% 20% 40%; // grid for 3 data points
                grid-auto-flow: row;
                position: relative;
                margin-top: 2rem;

                div {
                    margin: 0;

                    p {
                        z-index: 2;
                    }

                    p:first-of-type {
                        padding-bottom: .5rem;
                    }
                }

                .p-cont {
                    padding-top: 20px;
                    padding-left: 10px;
                    position: relative;
                    flex-direction: column;
                }
    

                #left-corner-cont, #right-corner-cont {
                    padding-left: 140px;
                    padding-top: 20px;

                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    
                    position: relative;
                    flex-direction: column;
                }

                /*#left-corner-cont {
                    background-image: url("../assets/bh10/main-site/3-about/purpleCornerSet.svg");
                }

                #right-corner-cont {
                    background-image: url("../assets/bh10/main-site/3-about/greenCornerSet.svg");
                }*/
            }
        }

        .p-cont {
            z-index: 2;
        }

        #brick-graphic {
            max-width: 100%;
            margin-left: 2rem;
        }
    }
}


// Schedule
#schedule {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    row-gap: 1rem;

    #schedule-left {
        display: flex;
        flex-flow: column;

        h2 {
            margin-bottom: 1rem;
        }

        #date-indicator-saturday, #date-indicator-sunday {
            padding: 0;
            margin-bottom: 1rem;
        }

        #schedule-days {
            //border: 2px solid $blue;
            border-radius: 6px;
            display: flex;
            row-gap: 0;
            margin-bottom: 1rem;
            overflow: hidden;
            width: 100%;

            .day {
                @include button-primary();
                background-color: $white;
                margin: 0;
                border-radius: 0px;
                border: none;
                width: 50%;

                &:active {
                    transform: translateY(0px);
                }

                &-active {
                    background-color: $blue;
                    color: $white;
                }
            }
        }
    }

    #schedule-calendar {
        //background-color: #FFFFFF;
        //border: 1px solid $white;
        //border-radius: 6px;
        padding: 0rem 3rem calc(1rem + 15px);
        width: 55%;
        align-self: flex-start;

        #feb-23-content {
            display: none;
        }

        .schedule-events .event {
            display: flex;
            flex-wrap: nowrap;
            column-gap: 1rem;
            padding: 1rem;
            margin-bottom: 1rem;
            border-radius: 6px;
            background-color: #FFFFFF;
            border: 1px solid $darker-blue;

            p {
                padding-bottom: 0rem;
                color: $darker-blue;
                width: 100%;
                text-align: center;
            }

            &-complete {
                /*opacity: 0.3;
                background-color: $darker-blue;
                border: none;*/
            }

            &-live {
                background-color: $darker-blue;
                border: none;

                p {
                    color: #FFFFFF;
                }
            }

            .time {
                opacity: 0.75;
                font-size: 1rem;
                text-align: left;
                width: 50%;
                margin: auto 0; // because it's smaller, centering it vertically
            }
            
            .location {
                opacity: 0.75;
                font-size: 1rem;
                text-align: right;
                width: 50%;
                margin: auto 0; // because it's smaller, centering it vertically
            }

            .live {
                font-weight: bold;
                color: $orange;
            }
        }
    }

    // About
    #about #about-content #about-buttons {
        width: 100%;
    }

    // // Leadership
    // #leadership #team {
    //     display: grid;
    //     grid-template-columns: 1fr 1fr 1fr;
    // }

    // Footer
    footer #footer-right {
        flex-direction: column;
        text-align: right;

        p:not(p:last-of-type) {
            padding-right: 0;
        }
    }
}

// FAQ
#faq {
    margin: 0 120px;

    #faq-title {
        display: flex;
        flex-wrap: wrap;
        align-items: left;

        #faq-title-content {
            margin-right: 3rem;
        }

        #confusedRicky {
            max-width: 100%;
            margin-top: 1rem;
        }
    }

    #faq-cards {
        margin: 40px 0 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        // TODO: This can be cleaner.
        $card-m: 20px;

        &-left {
            width: calc(50% - (#{$card-m} / 2));
            margin-right: calc($card-m / 2);
        }

        &-right {
            width: calc(50% - (#{$card-m} / 2));
            margin-left: calc($card-m / 2);
        }

        .card {
            width: 100%;
            margin-bottom: $card-m;
            border-radius: 6px;
            background-color: $darker-blue;
            border: 1px solid $white;

            &:active {
                transform: none;
            }

            .accordion-header {
                background-color: $white;
                cursor: pointer;
                outline: none;
                color: $darker-blue;
                font-weight: 600;
                text-align: left;
                padding: 20px 30px;
                border: none;
                border-radius: 0px 0px 6px 6px; // making bottom corners round and top corners filled
                width: 100%;
                font-size: var(--body-font-size);
                font-weight: bold;

                .fa-plus {
                    margin-right: 10px;

                    &-pastel-green {
                        color: $candid-blue;
                    }

                    &-teal {
                        color: $blue;
                    }

                    &-orange {
                        color: $orange;
                    }
                }

                .fa-minus {
                    margin-right: 10px;
                    color: $darker-blue;
                }

                &:hover {
                    background-color: $darker-blue;
                    border-radius: 0;
                    color: $white;

                    .fa-minus {
                        color: $white;
                    }
                }
            }

            .panel {
                display: none; // Toggled in JS
                padding: 20px 20px; // large side padding aligns with header text
                background-color: $darker-blue;

                p, em {
                    color: $white;
                    line-height: 23px;
                    margin-bottom: 10px;
                }
            }
        }

        .active .accordion-header {
            color: $darker-blue;
            background-color: $white;
            border: 2px solid $darker-blue;
            border-radius: 0px 0px 6px 6px; // making bottom corners round and top corners filled

            &:hover {
                border-radius: 0px 0px 6px 6px; // making bottom corners round and top corners filled
            }
        }
    }
}

// Prizes
#prizes {
    $tape-offset: 35px;  // how far into the card is the tape placed?
    
    @mixin tape-transform($rotation) {
        transform: translate(-50%, -50%) rotate($rotation);
        // this makes it so that we're positioning the tape by its center instead of its top-left, pre-rotation
        // corner, which is significantly easier
    }

    #prizes-ricky {
        // placeholder
        width: 85%;
        max-width: 980px;
        height: auto;
        background-color: $off-white;
        margin: 0 auto;
    }

    #prizes-card {
        background-color: $white;
        border: 1px solid $candid-blue;
        color: $darker-blue;
        border-radius: 6px;
        padding: 75px 95px;
        position: relative;
        width: 80%;

        & > .content {
            align-items: flex-end;
            column-gap: 50px;
            display: flex;
            flex-direction: row;

            .graphic {
                flex: 0 0 auto;
            }

            .text {
                display: flex;
                flex-direction: column;
                row-gap: 20px;

                .title {
                    color: $darker-blue;
                    font-weight: 400;
                }
            }
        }

        #inner-prizes-card {
            background-color: white;
            border: 1px solid $candid-blue;
            border-radius: 6px;
            padding: 35px;
            width: 100%;

            .title {
                padding-bottom: 20px;
            }

            .content {
                align-items: flex-start;
                display: flex;
                flex-direction: row;
                column-gap: 30px;
            }
        }

        #prizes-corner-text {
            font-size: 1.125rem;
            position: absolute;
            bottom: calc(75px / 2);
            right: 95px;
            opacity: 0.50;
            transform: translateY(50%);  // easy way to perfectly vertical center the text
        }
    }
}

// Sponsors
#sponsors {
    flex-direction: row;
    justify-content: space-around;

    #prospectusGraphic {
        margin: 0 1rem 0 1rem;
        max-width: 65%;
    }

    #sponsor-content {
        max-width: 90%;
        padding: 3rem 1rem 3rem 1rem;
        order: 2;
        z-index: 1;
        background-image: url("../assets/bh10/main-site/5-sponsors/coloredLinesSponsors.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;


        #sponsor-btns {
            justify-content: center;
            display: flex;
    
            #prospectus {
                @include button-primary();
                margin: 0 2rem 0 0;
            }
    
            #email {
                @include button-secondary();
                margin: 0;
            }
        }

        // #graphic-bg {
        //     order: 2;
        //     z-index: -1;
        // }
    }

    // #graphicwrap {
    //     border: 2px solid #ffffff;
    //     width: max-content;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     background-image: url("../assets/bh10/main-site/5-sponsors/coloredLinesSponsors.svg");
    // }
}

#sponsor-logos {
    margin: 0 120px;
    h3 {
        text-align: center;
    }
    
    .logo-group {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        row-gap: 3rem;
        column-gap: 5rem;
        padding: 2rem 0;

        img {
            transition: all 0.2s;

            &:hover {
                transform: translateY(-10px);
                transition: all 0.2s;
            }
        }
    }

    #brick-tier img {
        height: 275px;
        max-width: 100%;
    }

    #platinum-tier img {
        height: 255px;
        max-width: 100%;
    }

    #gold-tier img {
        height: 175px;
        max-width: 100%;
    }

    #bronze-silver-tier {

        img {
            height: 150px;
        }

        #paychex { // logo size messes with scaling
            height: 60px;
            max-width: 100%;
        }
    }
    
    #partners {
        padding-top: 1rem;

        img {
            height: 80px;
            max-width: 90%;
        }

        #standoutStickers { // their brand's padding messes with size consistancy
            height: 100px;
        }

        #wegmans { // logo size messes with scaling
            height: 125px;
            max-width: 100%;
        }
    }
}

// Leadership
#leadership #team {
    display: grid;
    grid-template-columns: .8fr .8fr .8fr .8fr;
    column-gap: 4rem;
    row-gap: 2.5rem;
    padding: 1rem;

    .leader {
        .leader-img {
            display: flex;
            position: relative;
            flex-flow: column;
            align-items: center;
            margin-bottom: 0.75rem;

            img {
                border-radius: 6px;
                width: 70%;
                z-index: 3;
            }
        }

        .leader-role {
            font-size: 1.2rem;
            opacity: 0.7;
            font-weight: 500;
            padding-bottom: 0px;
            padding-left: 10%;
        }

        .leader-name {
            font-weight: bold;
            padding-left: 15%;
        }
    }

    #rainbow-backing {
        position: absolute;
        //left: -10px;
        z-index: -1;
    }
}

// Contact
#contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    //min-height: auto;

    #contact-content {
        padding: 3rem 0 3rem 120px;
        background-image: url("../assets/bh10/main-site/6-contact/coloredLinesContact.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;

        a {
            border-bottom: 2px solid $dark-blue;
        }
    }

    // #graphicwrap {
    //     border: 2px solid #ffffff;
    //     width: max-content;
    //     background-repeat: no-repeat;
    //     background-size: contain;
    //     background-image: url("../assets/bh10/main-site/6-contact/coloredLinesContact.svg");
    // }

    // #line-graphic {
    //     order: 1;
    //     z-index: -1;
    // }

    #contact-socials {
        align-self: flex-end;
        background-image: url("../assets/bh10/main-site/6-contact/laptopContact.svg");
        width: 650px;
        max-width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center bottom;
        padding: 5rem 2rem 4rem 6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $darker-blue;


        #social-icons {
            display: flex;
            position: relative;
            flex-wrap: wrap;

            img {
                margin: 0.5rem;
                height: 30px;
            }
        }
    }
}

// Scroll Register End
#register-end {
    margin: 0 120px 3rem 120px;
    text-align: center;

    h1 {
        font-size: 5rem;
        margin-bottom: 2rem;
    }

    #register {
        @include button-primary();
        padding: 14px 113px;
    }
}

// Footer
footer {
    padding: 2rem 0rem;
    display: flex;
    justify-content: space-between;
    text-align: center;

    p {
        line-height: 2.5rem;
        text-decoration: none;
        padding: 0;
    }

    #footer-left {
        padding-right: 2rem;
    }

    #footer-right {
        display: flex;
        justify-content: space-between;

        p {
            padding-bottom: 0;

            &:not(p:last-of-type) {
                padding-right: 1rem;
            }
        }
    }
}

// Screen Sizes
@media screen and (max-width: 1500px) {
    // FAQ
    #faq {
        #faq-title {
            flex-direction: column;
            align-items: flex-start;
        }

        #faq-cards {
            flex-direction: column;

            &-left, &-right {
                margin: 0;
                width: 100%;
            }
        }
    }

    // About
    #about {

        #about-stats {

            #stats-content {
                
                #stats {

                    #left-corner-cont, #right-corner-cont {
                        padding-left: 90px;
                    }
                }
            }

        }
    }
}

@media screen and (max-width: 1220px) {
    // Nav
    nav {
        padding: 0 100px 0 30px;
    }

    // Section padding
    section {
        padding: 120px 0px 0px; // Set up to avoid nav overlap when linked
    }

    // Hero
    #hero {
        flex-direction: column;
        align-items: center;

        #hero-content {
            width: auto;
            flex-basis: auto;
            text-align: center;
            margin-bottom: 2rem;

            #hero-buttons { 
                margin: 0 auto;
            }
        }
    }

    // About
    #about #about-content #about-buttons {
        width: 100%;
    }

    #about {

        #about-stats {

            #stats-content {
    
                #stats {
                    
                    .p-cont {
                        padding-left: 10px;
                    }

                    #left-corner-cont, #right-corner-cont {
                        padding-left: 60px;
                    }
                }
            }
        }
    }

    // Leadership
    #leadership #team {
        display: grid;
        grid-template-columns: .8fr .8fr .8fr;
    }

    // Footer
    footer #footer-right {
        flex-direction: column;
        text-align: right;

        p:not(p:last-of-type) {
            padding-right: 0;
        }
    }
}

@media screen and (max-width: 955px) {
    // Nav
    nav {
        height: 70px;
        padding: 0px 100px 0px 3.5rem;

        #toggle {
            display: block;
        }

        #logo {
            width: 80px;
        }

        .link, #login {
            display: none;
        }
    }

    .show-nav {
        height: 100vh;
        width: 50vw;
        min-width: max-content;
        padding: 0px;
        border-bottom: none;
        border-right: 2px solid rgba($orange, 25%);
        flex-direction: column;
        justify-content: flex-start;
        z-index: 4;
        margin: 0;

        .top {
            height: 68px;
            margin: 0;

            #toggle {
                padding: 0 3.5rem;
                margin: 0;
            }
        }

        .left, .right {
            align-items: flex-start;
            flex-direction: column;
            margin: 2rem;

            .link {
                display: block;
                margin-right: 0rem;
                margin-top: 2rem;
            }
        }

        .right .link {
            margin: 1rem;
        }
    }

    #about {
        #about-cards {
            flex-direction: column;
            margin-bottom: 1rem;

            .about-card-space {
                width: 100%;

                .about-card {
                    width: 100%;

                    .about-header #blue4pt {
                        display: none;
                    }
                }

                .about-end {
                    display: none;
                }
            }

            .about-card-space:last-of-type {
                margin-top: 2rem;
            }
        }

        #about-stats {
            // flex-direction: column;
            grid-template-columns: 40%;
    
            #stats-content {
                width: 100%;
                margin: 0;
            }
    
            #brick-graphic {
                margin: 1rem 0 0;
                width: 100px;
            }
        }
    }

    // Prizes
    #prizes #prizes-card > .content {
        .graphic {
            display: none;
        }
        .text .title {
            letter-spacing: 0.5rem;
        }
    }
}



@media screen and (max-width: 900px) {
    // Variables
    :root {
        --body-font-size: 1rem;
    }

    // Typography
    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.75rem;
    }

    h3 {
        font-size: 1.35rem;
    }

    // Post
    #post #prizes-ricky {
        width: 90%;
    }

    // About
    #about #about-content {
        p {
            font-size: 1em;
        }

        #about-buttons {
            flex-direction: column;
            width: 60%;

            a {
                margin-left: 0;
            }
    
            #purple-star {
                top: 260px;
                left: 306px;
                rotate: -45deg;
            }
    
            #gradient-star-4pt {
                top: 260px;
                left: 160px;
                rotate: 15deg;
            }
    
            #gradient-star-7pt {
                bottom: 20px;
                right: 150px;
                rotate: 15deg;
            }
        }
    }

    #about {

        #about-stats {

            #stats-content {
                
                flex-direction: column;

                #stats {
                    grid-template-columns: 33% 34% 33%;

                    .p-cont, #left-corner-cont, #right-corner-cont {
                        background: none;
                        padding: 0;
                    }
                }
            }
        }
    }

    // Schedule
    #schedule {
        display: flex;
        flex-flow: column;

        #schedule-left {
            #checkmark-graphic, #rainbow_bricks-graphic {
                display: none;
            }
            .day {
                width: calc(50% - 0.75rem);

                &:first-of-type {
                    margin-right: 1rem;
                }
            }
        }

        #schedule-calendar {
            margin-top: 1rem;
            width: 100%;
        }
    }

    // Prizes
    #prizes {
        // $tape-offset: 12px;               // how far into the card is the tape placed?
        $overflow-size: 35px;             // how far does the outer card go into the section margins?
        // $inner-card-overflow-size: 20px;  // how far does the inner card go into the outer card's margins?

        #prizes-card {
            padding: $overflow-size;

            & > .content .text {
                row-gap: 15px;
            }

            // .tape {
            //     height: 12px;
            //     width: 50px;
    
            //     &.top-left {
            //         left: $tape-offset;
            //         top: $tape-offset;
            //     }
    
            //     &.top-right {
            //         right: $tape-offset - 50px;
            //         top: $tape-offset;
            //     }
    
            //     &.bottom-left {
            //         left: $tape-offset;
            //         bottom: $tape-offset - 12px;
            //     }
    
            //     &.bottom-right {
            //         right: $tape-offset - 50px;
            //         bottom: $tape-offset - 12px;
            //     }
            // }

            // #inner-prizes-card {
            //     margin-left: -$inner-card-overflow-size;
            //     padding: $inner-card-overflow-size;
            //     width: calc(100% + (2 * #{$inner-card-overflow-size}));

            //     .content {
            //         flex-direction: column;
            //         row-gap: 20px;
            //     }
            // }

            #prizes-corner-text {
                font-size: 0.75rem;
                right: 20px;
            }
        }
    }

    // Sponsors
    #sponsors {
        flex-direction: column-reverse;
        #sponsor-content  {
            padding: 3rem 120px;
            #sponsor-btns {
                flex-flow: column;
                width: max-content;
                margin: auto;

                #prospectus {
                    margin: 0rem 0rem 1rem 0rem;
                    padding: 1rem 3rem;
                }
            }
        }
        #prospectusGraphic {
            margin-left: 4rem;
            margin-right: 4rem;
        }

        .logo-group {
            padding: 1rem 0rem;
        }
    }

    // Leadership
    #leadership #team {
        display: grid;
        grid-template-columns: .8fr .8fr;

        .leader .leader-role {
            font-size: 0.9rem;
        }
    }

    // Contact
    #contact {
        flex-direction: column;

        #contact-content {
            width: 100%;
            padding: 3rem 120px;
        }

        #contact-socials {
            margin: 2rem auto -2px;
            max-width: 75%;
            width: 75%;
            height: calc((100vw - 7rem) * 0.56); // should be the same as 75% of the current width
            // Essentially: (100vw - section padding to get 100% width) * 0.75 to get the 75% width * 0.75 for 75% of 75% width
        }
    }

    // Footer
    footer p {
        line-height: 2rem;
    }
}

// Mobile Styling
@media screen and (max-width: 800px) {

    // Rest the left-right page margin (hacky)
    #hero {
        padding: 150px 0 0;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    #sponsors #sponsor-content {
        padding: 3rem 1rem;
    }
    #sponsor-logos {
        margin: 0 1rem;
    }
    #register-end {
        margin-left: 1rem;
        margin-right: 1rem;
        h1 {
            font-size: 3rem;
        }
    }
    #contact #contact-content {
        padding: 3rem 1rem;
    }
    #about #about-text, #about #about-cards, #about #about-stats, #faq {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    h1 {
        font-size: 2.8rem;
    // Rest the left-right page margin (hacky)
    #hero {
        padding: 150px 0 0;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    #sponsors #sponsor-content {
        padding: 3rem 1rem;
    }
    #sponsor-logos {
        margin: 0 1rem;
    }
    #register-end {
        margin-left: 1rem;
        margin-right: 1rem;
        h1 {
            font-size: 3rem;
        }
    }
    #contact #contact-content {
        padding: 3rem 1rem;
    }
    #about #about-text, #about #about-cards, #about #about-stats, #faq {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    h1 {
        font-size: 2.8rem;
    }

    #pre-reg {
    
        .mini-window {

            width: 94%;
            height: 100%;
            width: 94%;
            height: 100%;

            // Window adjustment to fit to screen
            p {
                padding-top: 1rem;
                margin-left: 1rem;
                margin-right: 1rem;
                margin-right: 1rem;
            }

            .title-bar {

                .title {
                    right: 5px;
                    bottom: 42px;
                    bottom: 42px;
                }
            }

        }

        #about-stats {
            flex-direction: column;

            #stats {
                display: flex;
            }

            #stats-content {
                width: 100%;
            }

            // video {
            //     max-width: 100%;
            //     padding-top: 2rem;
            // }
        }
    }

        // #about-stats {
        //     flex-direction: column;

        //     #stats-content {
        //         width: 100%;

        //         #stats {
        //             display: flex;
        //         }    
        //     }

        //     // video {
        //     //     max-width: 100%;
        //     //     padding-top: 2rem;
        //     // }
        // }

        #about {

            #about-stats {
    
                #stats-content {
                    
                    flex-direction: column;

                    #stats {
                        
                        grid-auto-flow: column;
                    }
                }
            }
        }
    }

    // Prizes
    #prizes #prizes-card > .content .text .title {
        letter-spacing: 0.5rem;
    }
}

@media screen and (max-width: 650px) {
    // Nav
    nav #register {
        display: none;
    }
    .show-nav #register {
        display: block;
    }

    // About
    #about #about-stats {
        #stats-content #stats {
            grid-template-columns: 100%;
            grid-template-rows: auto auto auto;
            row-gap: 2rem;

            div {
                padding: 0px 0px 20px;
            }
        }
        #brick-graphic { 
            display: none;
        }
    }

    // Schedule
    #schedule #schedule-left .day {
        width: 100%;

        &:first-of-type {
            margin-right: 0rem;
        }
    }

    // Prizes
    #prizes #prizes-card {
        padding: 20px;

        // #inner-prizes-card {
        //     margin-left: 0;
        //     width: 100%;
        // }
    }

    // Leadership
    #leadership #team {
        display: grid;
        grid-template-columns: .8fr;
    }

    // Contact
    #contact #contact-socials {
        max-width: 100%;
        width: 100%;
        justify-content: flex-start;
        padding: 0;
        margin: 2rem 0;
        background-image: none;
        height: auto;
        color: $white;

        #social-icons {
            background-color: white;
            border-radius: 6px;

            a i {
                font-size: 2rem;
            }
        }
    }

    // Footer
    footer {
        flex-direction: column;

        #footer-left {
            padding: 0;
        }
    }
}
